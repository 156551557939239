<template>
  <div class="score-query">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon class="btn" @click="$router.push('/protal')">
            <ArrowLeftBold />
          </el-icon>
        </div>
        个人信息
      </el-col>
    </el-row>
    <div style="padding: 0 0.4167rem">
      <el-form :inline="true" :model="form" label-position="right" label-width="140px">
        <div class="info-title">照片信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="正面照：" class="image1Url">
              <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                :on-change="handleAvatarChangeimage1Url">
                <img v-if="state.image1Url.length > 30" :src="state.image1Url" class="avatar" />
                <el-button v-if="state.image1Url.length > 30" link>修改</el-button>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <div class="info-title">基本信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名：">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="性别：">
              <el-select v-model="form.sex" placeholder="请选择">
                <el-option :key="0" label="男" value="0" />
                <el-option :key="1" label="女" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="民族：">
              <el-input v-model="form.nation" />
            </el-form-item>
            <el-form-item label="政治面貌：">
              <el-input v-model="form.politicsStatus" />
            </el-form-item>
            <el-form-item label="身份证号：">
              <el-input v-model="form.cardId" />
            </el-form-item>
            <el-form-item label="入学前户口所在地：">
              <el-input v-model="form.oldAddress" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现户口所在地：">
              <el-input v-model="form.newAddress" />
            </el-form-item>
            <el-form-item label="固定电话：">
              <el-input v-model="form.telephone" />
            </el-form-item>
            <el-form-item label="本人手机：">
              <el-input v-model="form.mobile" :disabled="true" />
            </el-form-item>
            <el-form-item label="家庭住址：">
              <el-input v-model="form.familyAddress" />
            </el-form-item>
            <el-form-item label="婚否：">
              <el-input v-model="form.marryState" />
            </el-form-item>
            <el-form-item label="婚迁日期：">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.marryChangeTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" /></el-config-provider>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="info-title">学历信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学历：">
              <el-select v-model="form.firstDegree" placeholder="请选择">
                <el-option :key="1" label="高中" value="高中" />
                <el-option :key="2" label="专科" value="专科" />
                <el-option :key="3" label="本科" value="本科" />
                <el-option :key="4" label="硕士" value="硕士" />
                <el-option :key="5" label="博士" value="博士" />
              </el-select>
            </el-form-item>
            <el-form-item label="毕业院校：">
              <el-input v-model="form.firstSchool" />
            </el-form-item>
            <el-form-item label="所学专业：">
              <el-input v-model="form.firstMajor" />
            </el-form-item>
            <el-form-item label="入学日期：">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.firstStartTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" /></el-config-provider>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="毕业日期：">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.firstEndTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
            <el-form-item label="学习形式：">
              <el-select v-model="form.firstType" placeholder="请选择">
                <el-option :key="1" label="全日制" value="全日制" />
                <el-option :key="2" label="非全日制" value="非全日制" />
              </el-select>
            </el-form-item>
            <el-form-item label="毕业结论：">
              <el-select v-model="form.firstCon" placeholder="请选择">
                <el-option :key="1" label="毕业" value="毕业" />
                <el-option :key="2" label="结业" value="结业" />
                <el-option :key="3" label="肄业" value="肄业" />
                <!-- <el-option :key="4" label="2024届应届毕业生" value="2024届应届毕业生" /> -->
              </el-select>
            </el-form-item>
            <el-form-item label="毕业证号：">
              <el-input v-model="form.firstNum" /> </el-form-item></el-col>
        </el-row>
        <div class="info-title">身份证照片</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="正面证件：" class="imageID1Url">
              <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                :on-change="handleAvatarChangeimageID1Url">
                <img v-if="state.imageID1Url.length > 30" :src="state.imageID1Url" class="avatar" />
                <el-button v-if="state.imageID1Url.length > 30" link>修改</el-button>
                <el-icon v-else class="avatar-uploader-icon" style="width: 238px; height: 142px">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="反面证件：" class="imageID2Url">
              <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                :on-change="handleAvatarChangeimageID2Url">
                <img v-if="state.imageID2Url.length > 30" :src="state.imageID2Url" class="avatar" />
                <el-button v-if="state.imageID2Url.length > 30" link>修改</el-button>
                <el-icon v-else class="avatar-uploader-icon" style="width: 238px; height: 142px">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 20px 0">
          <el-col :push="10">
            <el-button @click="$router.push('/portal')" style="width: 80px; margin-right: 10px">返回</el-button>
            <el-button type="primary" @click="onSubmit" style="width: 80px; margin-right: 10px">
              保存
            </el-button>
          </el-col>
        </el-row>
        <el-form-item> </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import area from "@/utils/area.json"
import { useRouter } from "vue-router";
import zhcn from 'element-plus/lib/locale/lang/zh-cn'
let router = useRouter();
const fileDownload = require('js-file-download')
onMounted(() => {
  getInfo()
})
//data
let state = reactive({
  image1Url: '', // 正面照路径
  imageID1Url: '', // 身份证正面照路径
  imageID2Url: '' // 身份证反面照路径
})
let store = useStore();
let form = reactive({
  name: '', // 考生姓名
  sex: undefined, // 性别
  nation: "", // 民族
  politicsStatus: '', // 政治面貌
  cardId: "", // 身份证号
  oldAddress: "", // 入学前户口所在地
  newAddress: "", // 现户口所在地
  telephone: "", // 固定电话
  mobile: store.state.user.userId, // 本人手机：
  familyAddress: "", // 家庭住址
  marryState: "", // 婚否
  marryChangeTime: "", // 婚迁日期
  firstDegree: "", // 学历
  firstSchool: "", // 毕业院校
  firstMajor: "", // 所学专业
  firstStartTime: "", // 入学日期
  firstEndTime: "", // 毕业日期
  firstType: "", // 学习形式
  firstCon: "", // 毕业结论
  firstNum: '', // 毕业证号
  examId: '', // 考试ID
})
let optionProps = reactive({
  value: "value",
  label: "label",
  children: "children"
})
// methods
// 保存个人信息
function onSubmit () {
  post("/examApply/user/editInfo", { ...form }).then(res => {
    if (res.code === 200) {
      ElMessage.success('保存成功')
      router.push('/portal')
    } else {
      return false
    }
  })
}
// 获取个人信息
function getInfo () {
  get("/examApply/user/getInfo").then(res => {
    console.log(res);
    Object.assign(form, res.result)
    state.image1Url = 'data:image/jpg;base64,' + res.result.photo
    state.imageID1Url = 'data:image/jpg;base64,' + res.result.idCardFront
    state.imageID2Url = 'data:image/jpg;base64,' + res.result.idCardBack
  })
}
function handleAvatarChangeimage1Url (file, fileList) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传图片只能是 JPG/JPEG/PNG 格式!");
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("图片大小不能超过100KB！");
    return false;
  } else {
    state.image1Url = URL.createObjectURL(file.raw);
    uploadImg(file, "image1Url");
  }
}
function handleAvatarChangeimageID1Url (file, fileList) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传图片只能是 JPG/JPEG/PNG 格式!");
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("图片大小不能超过100KB！");
    return false;
  } else {
    state.imageID1Url = URL.createObjectURL(file.raw);
    uploadImg(file, "imageID1Url");
  }
}
function handleAvatarChangeimageID2Url (file, fileList) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传图片只能是 JPG/JPEG/PNG 格式!");
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("图片大小不能超过100KB！");
    return false;
  } else {
    state.imageID2Url = URL.createObjectURL(file.raw);
    uploadImg(file, "imageID2Url");
  }
}
//文件上传操作
async function uploadImg (file, imgType) {
  let attachFile = new FormData();
  attachFile.append("attachFile", file.raw);
  // attachFile.append("examId", state.examInfo.examId);
  let res;
  // 正面照
  if (imgType === "image1Url") {
    res = await post("/examApply/user/uploadPicture", attachFile);
  }
  // 身份证正面照
  if (imgType === "imageID1Url") {
    res = await post("/examApply/user/uploadIdCardFront", attachFile);
  }
  // 身份证背面照
  if (imgType === "imageID2Url") {
    res = await post("/examApply/user/uploadIdCardBack", attachFile);
  }
  if (res.code == "200") {
    ElMessage.success("图片上传成功");
  } else {
    ElMessage.error("图片上传失败");
  }
}
</script>
<style lang="scss" scoped>
.score-query {
  min-height: 89vh;
  // padding: 0 0.4167rem;
  background-color: #ffffff;
}

.score-query-title {
  text-align: center;
  height: 0.3125rem;
  line-height: 0.3125rem;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 0.0052rem solid #e5e5e5;
  margin-bottom: 0.1042rem;

  .btn {
    position: absolute;
    left: -0.5729rem;
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}

.info-title {
  padding-left: 0.0521rem;
  border-left: 4px solid #097afa;
  font-size: 0.0938rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0.0521rem;
}

.el-col>div {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  float: right;
}

.enrollment-status {
  text-align: center;
  font-size: 0.1042rem;
  padding-bottom: 0.0521rem;
}

:deep(.el-input, .el-select, .el-cascader) {
  width: 1.5625rem;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

:deep(.el-upload) {
  align-items: flex-end;
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar {
  width: 0.5938rem;
  height: 0.7396rem;
}

.el-icon.avatar-uploader-icon {
  font-size: 0.1458rem;
  color: #8c939d;
  width: 0.5938rem;
  height: 0.7396rem;
  text-align: center;
  border: 1px solid #ccc;
}

.imageID1Url,
.imageID2Url {
  .avatar {
    width: 1.2396rem;
    height: 0.7396rem;
  }
}
</style>